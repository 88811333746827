// extracted by mini-css-extract-plugin
export var active = "navigation-module--active--uAacj";
export var block = "navigation-module--block--mYFK5";
export var description = "navigation-module--description--fXXOL";
export var headerInner = "navigation-module--headerInner--yXoau";
export var headerOuter = "navigation-module--headerOuter--qtKt8";
export var logoLink = "navigation-module--logoLink--NPKoz";
export var name = "navigation-module--name--8lxin";
export var navigation = "navigation-module--navigation--0oIjN";
export var navigationItem = "navigation-module--navigationItem--X3sZj";
export var title = "navigation-module--title--mrRUj";