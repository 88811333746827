import React from 'react'

import * as styles from './footer.module.css'

const Footer = () => (
  <div>
    <div className={styles.container}>
      <div>&copy; yayoi all rights reserved.</div>
    </div>
  </div>
)

export default Footer
